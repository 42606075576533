<template>
  <div class="login-box">

    <div class="login-form">
      <div class="login-header">
<!--        <img src="~@/assets/logo.png" class="logo" alt="logo">-->
        <span class="title">智慧消防可视化系统</span>
      </div>
      <a-form-model ref="form" :model="model" :rules="validatorRules">
        <a-form-model-item required prop="username">
          <a-input v-model="model.username" size="large" placeholder="请输入帐户名">
            <a-icon slot="prefix" type="user" :style="{ color: 'rgba(0,0,0,.25)' }"/>
          </a-input>
        </a-form-model-item>
        <a-form-model-item required prop="password">
          <a-input  v-model="model.password" size="large" type="password" autocomplete="false" placeholder="请输入密码">
            <a-icon slot="prefix" type="lock" :style="{ color: 'rgba(0,0,0,.25)' }"/>
          </a-input>
        </a-form-model-item>
        <a-form-model-item style="margin-top:24px">
          <a-button style="width: 100%;" size="large"  type="primary"  htmlType="submit"  class="login-button" @click="handleSubmit">确定
          </a-button>
        </a-form-model-item>

<!--        <a-row :gutter="0">-->
<!--          <a-col :span="16">-->
<!--            <a-form-model-item required prop="inputCode">-->
<!--              <a-input v-model="model.inputCode" size="large" type="text" placeholder="请输入验证码">-->
<!--                <a-icon slot="prefix" type="smile" :style="{ color: 'rgba(0,0,0,.25)' }"/>-->
<!--              </a-input>-->
<!--            </a-form-model-item>-->
<!--          </a-col>-->
<!--          <a-col :span="8" style="text-align: right">-->
<!--            <img v-if="requestCodeSuccess" style="margin-top: 2px;" :src="randCodeImage" @click="handleChangeCheckCode"/>-->
<!--            <img v-else style="margin-top: 2px;" src="../../assets/checkcode.png" @click="handleChangeCheckCode"/>-->
<!--          </a-col>-->
<!--        </a-row>-->
      </a-form-model>
    </div>
    <div class="beian">
      <img src="https://www.jckjcn.com/images/ba.png"  alt=""/><a href="https://beian.miit.gov.cn/" target="_blank" style="color: #1890ff">粤ICP备17109200号-1</a>&nbsp;Copyright, All Rights Reserved.

    </div>
  </div>
</template>
<script>


import {axiosServer} from "@/utils/request";
import router from "@/router";

export default {
  name: "Login",
  data(){
    return{
      model: {
        username: localStorage.getItem("rememberLoginUsername"),
        password: localStorage.getItem("rememberLoginPassword")
      },
      validatorRules: {
        username: [
          { required: true, message: '请输入用户名!' },
        ],
        password: [{
          required: true, message: '请输入密码!', validator: 'click'
        }],
      }
    }
  },
  methods: {
    handleSubmit(){
      axiosServer({
        url: '/sys/displayLogin',
        type: 'POST',
        params: {
          ...this.model
        }
      }).then(res => {
        console.log(res)
        if (res.code === 200){
          // 设置属性
          localStorage.setItem("assToken", res.result.token)
          localStorage.setItem("userInfo", JSON.stringify(res.result.userInfo))
          localStorage.setItem("rememberLoginUsername", this.model.username)
          localStorage.setItem("rememberLoginPassword", this.model.password)
          this.createLogo(res.result.userInfo.displayLogo);
          // 提示登录成功
          this.$notification.success({
            message:res.message || "登录成功"
          });
          this.$speech.speak({ text: "登录成功" }).then(() => {
            console.log("播报成功");
          })
          // 页面跳转
          router.push("/index");
        }else {
          this.$notification.error({
            message:res.message || "登录失败"
          });
          this.$speech.speak({ text: "登录失败" }).then(() => {
            console.log("播报成功");
          })
        }

      })
    },
    createLogo(logo){
      if (logo){
        let link = document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = logo;
        link.id="link_logo"
        document.getElementsByTagName('head')[0].appendChild(link);
        console.log("创建logo")
      }else {
        console.log("不创建logo")
      }

    }
  }
}
</script>


<style scoped lang="scss">
.login-box{
  width: 100%;
  height: 100%;
  background-size: cover;
  background: #1D242C url("../assets/pageBg.png") center center;

  .login-form{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 448px;
    padding: 40px 60px;
    border-radius: 4px;
    z-index: 999;
    .login-header{
      margin-bottom: 30px;
      display: flex;
      justify-content: center;
      //.logo {
      //
      //  height: 44px;
      //  vertical-align: top;
      //  margin-right: 16px;
      //  border-style: none;
      //}
      .title {
        margin-left: 20px;
        font-size: 24px;
        color: #ffffff;
        font-family: "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-weight: 600;
        position: relative;
        top: 2px;
      }
    }

  }
}
.beian{
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: center;
  color: #ffffff;
}
</style>